/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "맥북 전원 문제로 방송이 중단 되었습니다. 다음화로 이어집니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.airpremia.com/kr/ko"
  }, "에어프레미아 - AIR PREMIA")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://turo.com/"
  }, "Turo car sharing marketplace | Rent the perfect car")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.tesla.com/modely"
  }, "Model Y | Tesla")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.tesla.com/modelx"
  }, "Model X | Tesla")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.getty.edu/"
  }, "Getty: Resources for Visual Art and Cultural Heritage")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://griffithobservatory.org/"
  }, "Griffith Observatory - Southern California’s gateway to the cosmos!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.pstramway.com/ko/"
  }, "팜스프링 스 에어리얼 트램 웨이")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gousa.or.kr/destination/marina-del-rey"
  }, "마리나 델 레이 - GoUSA")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
